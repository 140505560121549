@each $spacerSize, $spacerValue in $spacers {
	.spacer--#{$spacerSize}-px{
		height: #{$spacerValue}px;
	}
	.spacer--#{$spacerSize}-vh{
		height: #{$spacerValue}vh;
	}
}

@include breakpoint(small only){
	.spacer--100-px{
		height: 50px;
	}
}

.container-spacer{
	height: rem-calc(200);
}
