.calendar{
	$dayWidth: rem-calc(64);
	$respDayWidth: rem-calc(34);
	max-width: rem-calc(630);
	>.grid-x {
		margin-bottom: rem-calc(40);
	}
	.grid-x{
		margin-bottom: rem-calc(20);
		&.header{
			margin-bottom: rem-calc(20);
		}
	}
	.header{
		text-align: center;
		color: $body-font-color;
		font-size: rem-calc(18);
		font-family: $cocogoose;
		&_day{
			display: inline-block;
			width: $respDayWidth;
			@include breakpoint(medium) {
				width: $dayWidth;
			}
		}
	}
	.has-arrow{
		&::after{
			font-size: rem-calc(16);
		}
	}
	.small_cal{
		width: 100%;
	}
	#small_cal_year{
		color: $secondary-color;
		font-family: $cocogoose;
		font-size: rem-calc(25);
		@include breakpoint(medium) {
			font-size: rem-calc(40);
		}
	}
	.cal{
		&_blank{
			span{
				display: block;
				width: $respDayWidth;
				height: $respDayWidth;
				@include breakpoint(medium) {
					width: $dayWidth;
					height: $dayWidth;
				}
			}
		}
		&_date{
			@include transition(all);
			@include border-radius($global-radius);
			padding:rem-calc(11);
			display:inline-block;
			text-align: center;
			margin:0 auto;
			font-family: $cocogoose;
			background:#FAFBFD;
			color:#A7A7A7;
			width: $respDayWidth;
			height: $respDayWidth;
			font-size: rem-calc(15);
			line-height: 1;
			@include breakpoint(medium) {
				line-height: 2;
				font-size: rem-calc(24);
				width: $dayWidth;
				height: $dayWidth;
			}
			&.is-active{
				background:$primary-color !important;
				color:$white !important;
			}
			&.is-clicked{
				background:darken($primary-color,25%) !important;
				color:$white !important;
			}
		}
		&_day{
			text-align: center;
			@include border-radius($global-radius);
			&.has-event{
				cursor: pointer;
				&:hover{
					background:$primary-color !important;
					color:$white !important;
				}
				.cal_date{
					background:$secondary-color;
				}
				.cal_date{
					color: $white;
				}
			}
		}
	}
}
