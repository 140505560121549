@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?wlz9dx');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?wlz9dx#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?wlz9dx') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?wlz9dx') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?wlz9dx##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-checkmark {
  &:before {
    content: $icon-checkmark; 
  }
}
.icon-breadcrumb {
  &:before {
    content: $icon-breadcrumb; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-arrow-medium {
  &:before {
    content: $icon-arrow-medium; 
  }
}
.icon-arrow-small {
  &:before {
    content: $icon-arrow-small; 
  }
}
.icon-arrow-big {
  &:before {
    content: $icon-arrow-big; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-mail {
  &:before {
    content: $icon-mail; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-whatsapp {
  &:before {
    content: $icon-whatsapp; 
  }
}

