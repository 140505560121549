.discover-carousel{
	&__container{
		background-color:#F0F2F5;
		padding:rem-calc(100 0);
		.grid-container {
			position: relative;
		}
		.slick-arrow{
			@include transition(all);
			@include vertical-center;
			width: rem-calc(33);
			height: rem-calc(26);
			display: inline-block;
			background-repeat: no-repeat;
			cursor: pointer;
			&.button-prev{
				left: -40px;
				background-image:url("../images/svg/banners/discover-carousel__prev.svg");
				&:hover{
					left: -50px;
				}
			}
			&.button-next{
				right: -40px;
				background-image:url("../images/svg/banners/discover-carousel__next.svg");
				&:hover{
					right: -50px;
				}
			}
			&.slick-disabled{
				opacity: 0;
			}
			&.slick-hidden{
				display: none;
			}
		}
	}
	.slick-track{
		display: flex;
		.slick-slide{
			display: flex;
			height: auto;
			justify-content: flex-end;
			>div{
				width: 100%;
			}
		}
	}
	.slick-dotted.slick-slider{
		margin-bottom: 0;
	}
	/* the slides */
	.slick-slide {
		margin:rem-calc( 0 15px);
	}
	/* the parent */
	.slick-list {
		margin: rem-calc(0 -15px);
	}
	.button-list{
		margin-top: rem-calc(15px);
	}
	.slick-dots {
		position: relative;
		bottom: 0;
		margin:0;
		list-style-type: none;
		text-align: center;
		li{
			margin:rem-calc(0 5px);
			margin-top: rem-calc(30);
			display: inline-block;
			cursor: pointer;
			width: 13px;
			height: 13px;
			border:2px solid #95A3D3;
			@include border-radius(4px);
			button{
				display: none;
			}
			&:only-child{
				display: none;
			}
		}
		.slick-active{
			background: #95A3D3;
		}
	}
	.button-list__item{
		a{
			&:hover{
				background: darken($tertiary-color, 25%);
			}
		}
	}
}
