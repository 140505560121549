@mixin aspect-ratio($width, $height) {
	position: relative;
	&:before {
		display: block;
		content: " ";
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}

	> .perspective-container, > .cases-item {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

///
/// Viewport sized typography with minimum and maximum values
///
/// @author Eduardo Boucas (@eduardoboucas)
///
/// @param {Number}   $responsive  - Viewport-based size
/// @param {Number}   $min         - Minimum font size (px)
/// @param {Number}   $max         - Maximum font size (px)
///                                  (optional)
/// @param {Number}   $fallback    - Fallback for viewport-
///                                  based units (optional)
///
/// @example scss - 5vw font size (with 50px fallback),
///                 minumum of 35px and maximum of 150px
///  @include responsive-font(5vw, 35px, 150px, 50px);
///
@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
	$responsive-unitless: $responsive / ($responsive - $responsive + 1);
	$dimension: if(unit($responsive) == 'vh', 'height', 'width');
	$min-breakpoint: $min / $responsive-unitless * 100;

	@media (max-#{$dimension}: #{$min-breakpoint}) {
		font-size: $min;
	}

	@if $max {
		$max-breakpoint: $max / $responsive-unitless * 100;

		@media (min-#{$dimension}: #{$max-breakpoint}) {
			font-size: $max;
		}
	}

	@if $fallback {
		font-size: $fallback;
	}

	font-size: $responsive;
}

@mixin placeholder {
	&::-webkit-input-placeholder {
		@content
	}
	&:-moz-placeholder {
		@content
	}
	&::-moz-placeholder {
		@content
	}
	&:-ms-input-placeholder {
		@content
	}
}

@mixin autofill {
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus{
		background-color:transparent !important;
		-webkit-text-fill-color: $secondary-color !important;
		-webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
		transition: background-color 5000s ease-in-out 0s !important;
	}
}

@mixin box-shadow($value){
	-webkit-box-shadow: $value;
	-moz-box-shadow: $value;
	box-shadow: $value;
}

@mixin banner-shadow{
	-webkit-box-shadow: 0 15px 30px rgba(0,0,0,0.16);
	-moz-box-shadow: 0 15px 30px rgba(0,0,0,0.16);
	box-shadow: 0 15px 30px rgba(0,0,0,0.16);
}

@mixin drop-shadow{
	-webkit-box-shadow: 0 15px 30px rgba(0,0,0,0.16);
	-moz-box-shadow: 0 15px 30px rgba(0,0,0,0.16);
	box-shadow: 0 15px 30px rgba(0,0,0,0.16);
}

@mixin translateY($prop) {
	-webkit-transform: translateY($prop);
	-moz-transform: translateY($prop);
	-ms-transform: translateY($prop);
	-o-transform: translateY($prop);
	transform: translateY($prop);
}

@mixin translateX($prop) {
	-webkit-transform: translateX($prop);
	-moz-transform: translateX($prop);
	-ms-transform: translateX($prop);
	-o-transform: translateX($prop);
	transform: translateX($prop);
}

@mixin translateXY($prop) {
	-webkit-transform: translateX($prop) translateY($prop);
	-moz-transform: translateX($prop) translateY($prop);
	-ms-transform: translateX($prop) translateY($prop);
	-o-transform: translateX($prop) translateY($prop);
	transform: translateX($prop) translateY($prop);
}

@mixin transform($prop) {
	-webkit-transform: $prop;
	-moz-transform: $prop;
	-ms-transform: $prop;
	-o-transform: $prop;
	transform: $prop;
}

@mixin transition($prop) {
	-webkit-transition: $prop 150ms ease-in-out;
	-moz-transition: $prop 150ms ease-in-out;
	-ms-transition: $prop 150ms ease-in-out;
	-o-transition: $prop 150ms ease-in-out;
	transition: $prop 150ms ease-in-out;
}

@mixin cover-bg{
	-webkit-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;
}

@mixin text-spects {
	h1,h2,h3,h4,h5,h6,p{
		@content
	}
}

@mixin landscape-medium-down{
	@include breakpoint(medium down) {
		@include breakpoint(landscape){
			@content
		}
	}
}

@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@mixin fadeIn{
	opacity:0;
	animation:fadeIn ease-in 1;
	animation-fill-mode:forwards;
	animation-duration:500ms;
	animation-delay: 500ms;
}