.button{
	@each $colorName, $colorValue in $colors{
		&--#{$colorName}{
			@include transition(all);
			@include border-radius($global-radius);
			padding: $button-padding;
			display: inline-block;
			font-weight: 700;
			font-size: rem-calc(16);
			@if $colorName == 'white' {
				color: $primary-color;
				background:$colorValue;
			}@else {
				background:$colorValue;
				color:$white;
			}
			&:hover,&:focus{
				background:$secondary-color;
				@if $colorName == 'white' {
					color: $primary-color;
				}@else {
					color: $white !important;
				}
			}
			&.has-download{
				padding-right: rem-calc(50);
				position: relative;
				width: 100%;
				display: block;
				text-align: left;
				&::after{
					@include vertical-center;
					right: rem-calc(20px);
					position: absolute;
					font-family: Canisius;
					content: '\e903';
					margin-left: rem-calc(5);
					font-size: rem-calc(15);
				}
			}
			&.has-arrow{
				position: relative;
				padding-right: rem-calc(40);
				&::after{
					@include vertical-center;
					right: rem-calc(15);
				}
			}
			&.full{
				width: 100%;
			}
		}
	}
	&--back{
		font-size: rem-calc(24);
		font-weight: 300;
		color:#9D9D9D;
		&::before{
			@include transition(color);
			color:$body-font-color;
			font-family: Canisius;
			margin-right: rem-calc(10);
			content: '\e907';
			position: relative;
			top: rem-calc(-1);
			font-size: rem-calc(18);
		}
		&:hover{
			&::before{
				color: $primary-color;
			}
		}
	}
}
button{
	cursor: pointer;
	padding: rem-calc(18px) !important;
}
