@import "footer-menu";
@import "main-menu";
@import "resp-menu";
@import "submenu";
@import "top-menu";


nav{
	@include transition(height);
	position:fixed;
	height: $responsiveMenuHeight;
	@include breakpoint(large) {
		height: $menuHeight;
	}
	background: $white;
	width: 100%;
	z-index: 999;
	.clearfix{
		@include transition(height);
		height:rem-calc(50);
	}
	&.has-scrolled{
		height: $responsiveMenuHeight;
		@include breakpoint(large) {
			height: 80px;
		}
		.logo{
			padding: rem-calc(10px 0 14px);
			width: rem-calc(118);
			@include breakpoint(large) {
				padding: rem-calc(15px 0 19px);
				width: rem-calc(140);
			}
		}
		.clearfix{
			overflow: hidden;
			height: 0;
		}
		.search,
		.main-menu__submenu{
			@include breakpoint(large){
				top:rem-calc(80px) !important;
			}
		}
		.main-menu__item {
			height: rem-calc(49);
		}
	}
}
