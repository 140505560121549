$icomoon-font-family: "Canisius" !default;
$icomoon-font-path: "fonts" !default;

$icon-close: "\e90e";
$icon-checkmark: "\e90d";
$icon-breadcrumb: "\e90c";
$icon-arrow-left: "\e907";
$icon-search: "\e905";
$icon-arrow-down: "\e904";
$icon-arrow-up: "\e909";
$icon-download: "\e903";
$icon-arrow-medium: "\e900";
$icon-arrow-small: "\e901";
$icon-arrow-big: "\e902";
$icon-facebook: "\e906";
$icon-instagram: "\e908";
$icon-linkedin: "\e90a";
$icon-mail: "\e90b";
$icon-twitter: "\e90f";
$icon-whatsapp: "\e910";

