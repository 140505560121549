@mixin iconSize{
	@include transition(all);
	@include border-radius($global-radius);
	cursor: pointer;
	width: rem-calc(35);
	height: rem-calc(35);
}

.social-list{
	display: inline-block;
	margin:0;
	padding:0;
	li{
		display: inline-block;
		text-align: left;
		@include iconSize;
		+li{
			margin-left: 5px;
		}
		&.instagram {
			background: #f09433;
			background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
			background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
			background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
			a{
				background: rgba($primary-color,0);
			}
			&:hover{
				a{
					background: rgba($primary-color,1);
				}
			}
		}
		&.facebook {
			a{
				background: #547BBC;
			}
		}
		&.twitter {
			a{
				background: #78CBEF;
			}
		}
		&.linkedin {
			a{
				background: #1384C7;
			}
		}
		a{
			@include iconSize;
			text-align: center;
			display: inline-block;
			padding:0 !important;
			i{
				&:before{
					font-size: rem-calc(16);
				}
				color:$white;
				line-height: rem-calc(36);
			}
			&:hover{
				background:$primary-color;
			}
		}
	}
	.dropdown-pane{
		border:0;
		box-shadow: 0 5px 5px rgba(#000000,.25);
		a{
			display: block;
			font-size: rem-calc(14);
			padding:rem-calc(5 15);
			color:$body-font-color;
			line-height: 1.3;
			i{
				position: relative;
				line-height: 1.3;
				margin-right: rem-calc(4);
			}
			&:hover{
				background:rgba(#95A3D3,.1);
				color:$secondary-color;
				i{
					color:$secondary-color;
				}
			}
		}
	}
}
