$white: #FFFFFF;
$black: #000000;
$tertiary-color: #95A3D3;
$light-blue: #FAFBFD;
$succes-color: #89D883;
$gray: #9D9D9D;

//FONTS
$cocogoose:'cocogooseregular';
$cocogoose-light:'cocogooselight';

$menuHeight: 140px;
$responsiveMenuHeight: 60px;



$pullLeft: -165px;
