@each $colorName, $colorValue in $colors {
  .background--#{$colorName} {
    @if $colorName == 'light-gray' {
      *{
        color: $secondary-color;
      }
    } @elseif $colorName == 'light-blue' {
      *{
        color: $secondary-color;
      }
    } @else {
      *{
        color: $white;
      }
    }
    @if $colorName == 'white' {
      background: $white;
    } @else {
      background: $colorValue;
    }
  }
}

$opacities: (
        '10' : 0.1,
        '20' : 0.2,
        '30' : 0.3,
        '40' : 0.4,
        '50' : 0.5,
        '60' : 0.6,
        '70' : 0.7,
        '80' : 0.8,
        '90' : 0.9,
);
@each $opacityName, $opacityValue in $opacities {
  .backgroundOpacity--#{$opacityName} {
    opacity: $opacityValue;
  }
}

.hasBackgroundImage {
  position: relative;
  .background {
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    & ~ * {
      position: relative;
    }
  }
  .backgroundCover {
    background-size: cover;
  }
  .backgroundRepeat {
    background-repeat: repeat;
  }
}

.hasParallax {
  overflow: hidden;
  .background {
    height: 180%;
    top: -40%;
  }
}

.parallax-background {

}