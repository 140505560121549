.news-list{
	a{
		color:$body-font-color;
		@include cover-bg;
		@include border-radius($global-radius);
		padding-bottom: rem-calc(20px);
	}
	&__excerpt-image{
		@include aspect-ratio(4, 2);
		@include cover-bg;
		@include border-radius($global-radius);
		margin-bottom: rem-calc(24);
	}
	&__date{
		font-size: rem-calc(14);
		font-weight: 300;
		text-transform: lowercase;
	}
	&__more{
		@include transition(all);
		font-size: rem-calc(14);
		font-weight: 700;
		color:$secondary-color;
		&:hover{
			color:$primary-color;
		}
	}
	i{
		&::before{
			font-size: rem-calc(12);
		}
	}
	.cell {
		margin-bottom: rem-calc(30);
	}
	&-featured {
		.cell {
			@include breakpoint(medium) {
				width: calc(50% - 1.875rem);
			}
			@include breakpoint(large) {
				width: calc(33.33333% - 1.875rem);
				&:first-child {
					width: calc(66.66667% - 1.875rem);
				}
			}
		}
	}
}
