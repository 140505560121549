.button-list{
	$p: &;
	margin:0;
	&__item {
		display: block;
	}
	&--vertical{
		li,a {
			display: block;
		}
		#{$p}__item {
			+ li {
				margin-top: 10px;
			}
		}
	}
}