.no-padding {
	padding: 0 !important;
}

$paddings: (
		'0' : 0,
		'1' : 1,
		'2' : 2,
		'3' : 3,
		'4' : 4,
		'5' : 5,
		'10' : 10,
		'20' : 20,
		'30' : 30,
		'40' : 40,
		'50' : 50,
		'xl': 100,
		'l': 75,
		'm': 50,
		's': 25,
);
$paddingSides: (
		't' : 'top',
		'b' : 'bottom',
		'l' : 'left',
		'r' : 'right',
);
$paddingBreakPoints: (
		's-': 'small',
		'm-': 'medium',
		'l-': 'large',
		'xl-': 'xlarge',
		'': 'global',
);
@each $side, $sideValue in $paddingSides {
	@each $paddingSize, $paddingValue in $paddings {
		@include breakpoint(small only) {
			.s-p#{$side}-#{$paddingSize} {
				padding-#{$sideValue}: #{$paddingValue}px;
			}
		}
		@include breakpoint(medium) {
			.m-p#{$side}-#{$paddingSize} {
				padding-#{$sideValue}: #{$paddingValue}px;
			}
		}
		@include breakpoint(large) {
			.l-p#{$side}-#{$paddingSize} {
				padding-#{$sideValue}: #{$paddingValue}px;
			}
		}
		@include breakpoint(xlarge) {
			.xl-p#{$side}-#{$paddingSize} {
				padding-#{$sideValue}: #{$paddingValue}px;
			}
		}

		.p#{$side}-#{$paddingSize} {
			padding-#{$sideValue}: #{$paddingValue}px;
		}
	}
}
