.logo{
	@include transition(all);
	display: inline-block;
	padding: rem-calc(10px 0 14px);
	width: rem-calc(118);
	@include breakpoint(large) {
		padding: rem-calc(25px 0 29px);
		width: rem-calc(252);
	}
}

nav{
	border-bottom:1px solid #E5E5E5;
}

.main-menu{
	$p: &;
	display: block;
	list-style: none;
	margin:0;
	padding-top: rem-calc(31px);
    text-align: right;
	&__item{
		display: inline-block;
		height: rem-calc(63);
		position: relative;
		+li{
			a{
				padding: rem-calc(0 15);
			}
		}
		&:last-child{
			a{
				padding-right: 0;
			}
		}
		&:hover{
			#{$p}__submenu{
				z-index: 0;
				opacity: 1;
				transition:max-height 500ms ease-in-out;
				max-height:rem-calc(999);
				visibility: visible;
			}
		}
		&.has-submenu {
			a {
				&::after {
					content: '';
					width: rem-calc(19);
					height: rem-calc(19);
					display: inline-block;
					background:white;
					position: absolute;
					bottom: -40px;
					z-index: 5;
					left: 50%;
					transform: translateX(-50%) rotate(45deg);
					border-radius: $global-radius;
					//@include transition(opacity);
					opacity: 0;
				}
			}
			&:hover {
				a{
					&::after{
						opacity: 1;
					}
				}
			}
		}
	}
	a,span{
		display: inline-block;
		cursor: pointer;
		font-size: rem-calc(14);
		color:$body-font-color;
		font-weight: 700;
		position: relative;
		&.is-active,
		&.child-active,
		&:hover{
			color:$primary-color;
		}
	}
	span{
		display: inline-block;
		&:hover,
		&[aria-expanded="true"]{
			color:$primary-color;
			i{
				color:$primary-color;
			}
		}
		i{
			@include transition(color);
			cursor: pointer;
			position: relative;
			top: 2px;
		}
	}
	&__submenu{
		@extend .background--secondary;
		transition:max-height 500ms ease-in-out;
		max-height:rem-calc(0);
		visibility: hidden;
		overflow: hidden;
		opacity: 0;
		z-index: -100;
		width: 100%;
		border-radius: 0;
		position: fixed;
		top: $menuHeight !important;
        text-align: left;
		left: 0 !important;
		box-shadow: 0px 50px 100px 1px rgba(#000000,0.2);
		>div{
			padding-top:rem-calc(20);
			padding-bottom:rem-calc(18);
		}
		h6,a{
			font-size: rem-calc(14);
			font-family: $body-font-family;
			font-weight: 500;
		}
		ul,li{
			list-style-type: none;
			margin:0;
			padding:0;
		}
		li{
			margin-top: rem-calc(15);
		}
		a{
			@include transition(all);
			opacity: .55;
			color:$white;
			padding: 0 !important;
			&::after{
				display: none !important;
			}
			&.is-active,
			&:hover{
				color:$white;
				opacity: 1;
			}
		}
	}
}
.has-scrolled {
	.main-menu__item.has-submenu a::after {
		bottom: -30px;
	}
}
