ul,ol{
	li,a{
		font-family: $body-font-family;
		color:$body-font-color;
		font-size: rem-calc(16);
	}
	&.inline-list{
		list-style-type: none;
		margin:0;
	}
	&.vertical-list{
		list-style-type: none;
		margin:0;
		li{
			width: 100%;
			margin-top: rem-calc(10);
		}
	}
}