.news-category{
	list-style: none;
	margin:0;
	li{
		display: inline-block;
		+li {
			margin-left: rem-calc(15);
		}
	}
	a{
		@include transition(all);
		text-transform: uppercase;
		font-size: rem-calc(14);
		color:$secondary-color;
		opacity: .4;
		&:hover,
		&.is-active{
			opacity: 1;
		}
	}
}