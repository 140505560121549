@font-face {
	font-family: 'cocogooseregular';
	src: url('../fonts/cocogoose-webfont.woff2') format('woff2'),
	url('../fonts/cocogoose-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'cocogooselight';
	src: url('../fonts/cocogoose-light-webfont.woff2') format('woff2'),
	url('../fonts/cocogoose-light-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@import '../../dist/fonts/Canisius/style.scss';