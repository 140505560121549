.video-banner{
	@include cover-bg;
	position: relative;
	&__content{
		@include vertical-center;
		z-index: 5;
		width: 100%;
	}
	&__inner-content{
		position: relative;
		&::before{
			content: '';
			position: absolute;
			left: rem-calc(-50);
			background-image: url('../images/svg/banners/video-content.svg');
			background-repeat: no-repeat;
			width: rem-calc(250);
			height: rem-calc(250);
			@include translateY(-10%);

			@include breakpoint(medium){
				@include translateY(-26%);
				width: rem-calc(400);
				height: rem-calc(350);

			}
			@include breakpoint(large){
				@include translateY(-40%);
				width: rem-calc(584);
				height: rem-calc(466);

			}
		}
		@include breakpoint(small only){
			.large-arrow {
				width: 40px;
				height: 30px;
			}
		}
		div{
			position: relative;
			z-index: 1;
		}
	}
	p{
		font-size: rem-calc(14);
		font-weight: 700;
	}
	iframe {
		@include transition(opacity);
		opacity: 0;
		pointer-events: none;
	}
	&.has-started{
		iframe {
			opacity: 1;
			pointer-events: inherit;
		}
	}
}
.page__content {
	.grid-container {
		.video-banner {
			//background: red;

			.video-banner__inner-content::before {
				width: 93%;
				height: 0;
				padding-bottom: 79.66%;
				-webkit-transform: none;
				transform: translateY(-50%) scale(1);
				left: -30px;
				top: 40%;
				@include breakpoint(medium) {
					width: 100%;
					height: 0;
					padding-bottom: 79.66%;
					-webkit-transform: none;
					transform: translateY(-50%) scale(1.16);
					left: -30px;
					top: 30%;
				}

			}
		}
	}
}