.home-usp{
	margin-top: rem-calc(-100px);
	z-index: 5;
	position: relative;
	.card.has-image{
		min-height: rem-calc(350px);
	}
	&__casepicker{
		flex-grow: 1;
		justify-content: space-between;
		flex-direction: column;
		display: flex;
		z-index: 5;
		.select{
			margin-bottom: rem-calc(20);
			@include breakpoint(medium) {
				margin-bottom: rem-calc(100);
			}
		}
	}
	.is-disabled{
		cursor: not-allowed;
		a{
			pointer-events: none;
			background:$gray;
			opacity: 0.5;
		}
	}
}
