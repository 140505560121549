.landing-container{
	.banner{
		height: rem-calc(300);
		@include breakpoint(medium) {
			height: rem-calc(400);
		}

		@include breakpoint(large) {
			height: rem-calc(600);
		}
		@include breakpoint(xlarge) {
			height: rem-calc(800);
		}
		position: relative;
		z-index: 1;
		&::before{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			content:'';
			display: block;
			background-image: url("../images/svg/mask/landingMask-scalable.svg");
			@include breakpoint(large) {
				background-image: url("../images/svg/mask/landingMask.svg");
			}
			background-repeat: no-repeat;
			background-size: cover;
			background-position: right bottom;
		}
		&-background {
			position: absolute;
			top: 0px;
			right: 0px;
			bottom: 0px;
			z-index: -1;
			background-size: cover;
			background-position: right center;
			left: 20%;
			@include breakpoint(large) {
				left: 30%;
			}
			@include breakpoint(xlarge) {
				left: 30%;
			}
		}
		h1{
			color:$secondary-color;
		}
		p{
			color:#95A3D3;
		}
		.grid-container{
			position: relative;
		}
	}
}