.search-results{
	margin:20px 0;
	list-style-type: none;
	&__item{
		display: block;
		border-bottom:4px solid $light-gray;
		padding:25px 0;
	}
	&__url,span{
		color:$secondary-color;
	}

	span{
		i{
			margin-left: 5px;
			font-size: rem-calc(12px);
		}
	}
	a{
		display: block;
	}
}