.image-mask{
	&--right{
		width: rem-calc(500);
		height: rem-calc(310);
		@include breakpoint(1100px) {
			width: rem-calc(1100);
			height: rem-calc(690);
		}
	}
	&--left{
		float: right;
		width: rem-calc(425);
		height: rem-calc(300);
		@include breakpoint(1100px) {
			width: rem-calc(1010);
			height: rem-calc(693);
		}
	}
}