[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea{
	margin-bottom: 0;
	border-bottom:2px solid #DEDEDE;
	@include autofill;
	&:focus{
		border-bottom:2px solid #DEDEDE;
	}
}

textarea{
	padding-top:rem-calc(13);
	height:rem-calc(140) !important;
	resize: none;
}

.form-group{
	margin-bottom: rem-calc(25px);
	position: relative;
	&.checkbox{
		label,a{
			font-size: rem-calc(16);
			font-weight: 500;
		}
		label{
			margin:0;
			position: relative;
			top: 0;
			padding-left: rem-calc(25);
		}
		color:$body-font-color;
		position: relative;
		input{
			margin:0;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			& + label{
				position: relative;
				&::before{
					@include transition(all);
					border-radius:rem-calc(4);
					position: absolute;
					left: 0;
					top: rem-calc(4);
					width: rem-calc(17);
					height: rem-calc(17);
					content: '';
					border: 2px solid #E5E5E5;
				}
				&::after{
					@include transition(all);
					font-family: Canisius;
					font-size: rem-calc(8);
					position: absolute;
					left: rem-calc(3);
					top: rem-calc(6);
					content: '\e90d';
					color:$succes-color;
					opacity: 0;
				}
			}
			&:checked + label{
				&::before {
					border-color: $succes-color;
				}
				&::after{
					opacity: 1;
				}
			}
		}
	}
	label{
		font-size: rem-calc(16);
		position: absolute;
		top: rem-calc(11);
		color:$body-font-color;
	}
	[type='text'],[type='email'],[type='tel'],textarea{
		z-index: 1;
		position: relative;
		height: rem-calc(50);
		&.is-invalid-input + label,
		&.valid + label,
		&:focus + label{
			top: rem-calc(-10);
			font-size: rem-calc(12);
			color:#95A3D3;
		}
		&.is-invalid-input{
			background: transparent;
			border-color:$alert-color;
			+ label{
				color:$alert-color;
			}
		}
		&.valid{
			border-color: #C8E2C6;
		}
		&[required]{
			+ label{
				&::after{
					content: '*';
					color:$alert-color;
				}
			}
		}
	}
	&.passed{
		[type='text'],[type='email'],[type='tel'],textarea{
			+ label {
				top: rem-calc(-10);
				font-size: rem-calc(12);
				color: #95A3D3;
			}
		}
	}
	.form-error {
		margin-top: 7px;
		margin-bottom: 1.5rem;
	}
}

$background: $light-gray;
$select-color: $body-font-color;
$select-background: $white;
$select-width: 100%;
$select-height: rem-calc(50);

.select-hidden {
	display: none;
	visibility: hidden;
	padding-right: 10px;
}
.select {
	@include border-radius($global-radius);
	cursor: pointer;
	display: inline-block;
	position: relative;
	color: $select-color;
	width: $select-width;
	height: $select-height;
	z-index: 1;
}
.select-styled {
	@include border-radius($global-radius);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	left: 0;
	background-color: $select-background;
	padding: $button-padding;
	box-shadow: 0 2px 6px rgba(#000000, .16);
	&:after {
		@include transition(all);
		font-family: Canisius;
		content:"\e904";
		position: absolute;
		top: rem-calc(15);
		font-size: rem-calc(14);
		right: rem-calc(16);
	}
	&:hover {
	}
	&:active, &.active {
		box-shadow: none;
		&:after {
			transform: rotate(180deg);
		}
	}
}

.select-options {
	@include border-radius($global-radius);
	display: none;
	background: $select-background;
	position: absolute;
	box-shadow: 0 2px 6px rgba(#000000, .16);
	top: 0;
	right: 0;
	padding: 0;
	left: 0;
	margin: 0;
	list-style: none;
	li{
		@include transition(all 0.15s ease-in);
		margin: 0;
		padding: $button-padding;
		&:hover {
			color: $secondary-color;
			background: $light-blue;
		}
		&[rel="hide"] {
			display: none;
		}
	}
}
