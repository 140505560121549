.banner{
	position: relative;
	padding:rem-calc(40px 0);
	@include cover-bg;
	@include breakpoint(medium) {
		padding: rem-calc(75px 0 125px);
	}
	&__overlay{
		position: absolute;
		top: 100%;
		transform: translateY(-50%);
		right: rem-calc(-2);
		display: block;
		width: calc(100% + 2px);
		z-index: 5;
		pointer-events: none;
	}
	&.has-overlay {
		overflow: visible;
		.grid-container{
			z-index: 5;
			position: relative;
		}
		&::before{
			@include transition(all);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 1;
			content: '';
			height: 100%;
			opacity: .75;
		}
		@each $colorName, $colorValue in $colors {
			&--#{$colorName} {
				@extend .has-overlay;
				&::before {
					background: linear-gradient(to right, rgba($colorValue, .9) 0%, rgba($white, 0) 50%);
				}
			}
		}
	}
	@include breakpoint(large) {
		padding: rem-calc(100px 0 135px);
	}
	h1,p{
		color:$white;
	}
	p{
		font-size: rem-calc(14);
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: rem-calc(25);
	}
	.grid-container,
	.grid-x{
		height: 100%;
	}
	>.grid-container{
		>.grid-x{
			>.cell{
				@include breakpoint(1490px) {
					margin-left: $pullLeft;
				}
			}
		}
	}
}
