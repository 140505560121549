.faq{
	@extend .has-border;
	border-radius:$global-radius;
	.accordion{
		&-title{
			font-weight: 700;
			color: $secondary-color;
			font-size: rem-calc(18);
		}
	}
	li{
		&:first-child{
			border-top:0;
		}
		.card__content{
			padding-top:0;
			padding-right: rem-calc(40);
		}
	}
	&-item{
		&__buttons{
			margin:0;
			li{
				list-style-type: none;
				background:transparent;
				a{
					color:$body-font-color;
					font-size: rem-calc(16);
					&:hover{
						background: $secondary-color;
					}
				}
				&:nth-child(1){
					margin-right: rem-calc(20);
					&.is-active{
						a {
							background: $secondary-color;
							color: $white;
						}
					}
				}
				&:nth-child(2){
					&.is-active{
						a {
							background: #ED6B6B;
							color: $white;
						}
					}
				}
			}
		}
		&__content{
			background:transparent;
			border:0;
			.tabs-panel{
				padding:rem-calc(20 0 0 0);
				p{
				a{
					color:$secondary-color;
				}
				}
			}
		}
	}
	&-item__card{
		display: none;
		padding-top: 20px;
		background:#FAFBFD;
		a{
			color:$secondary-color !important;
			font-weight: 700 !important;
		}
	}
}

.accordion{
	&-item{
		.card{
			background:#FAFBFD;
		}
		&.is-active{
			background: #FAFBFD;
		}
	}
	&-title{
		@include breakpoint(medium down){
			padding-left: rem-calc(15);

		}
		&:hover,
		&[aria-expanded="true"]{
			background:#FAFBFD;
			color:$secondary-color;
		}
	}
}

