//Sticky footer
.page__container{
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}
.page__content{
	flex: 1 0 auto;
}

footer {
	flex-shrink: 0;
}

@include breakpoint(large){
	hr + .two-column__container{
		margin-top:rem-calc(-50px);
	}
	.two-column__container + hr{
		margin-top:rem-calc(-50px);
	}
}

@include breakpoint(medium only){
	hr + .two-column__container{
		padding-top:rem-calc(100px);
	}
	.two-column__container + hr{
		margin-top:rem-calc(100px);
	}
}

@include breakpoint(small only){
	hr + .two-column__container{
		padding-top:rem-calc(50px);
	}
	.two-column__container + hr{
		margin-top:rem-calc(50px);
	}
}
.block__title {
	@include xy-grid-container()
}