.tick {
	width: 100px; // use width: 64, height: 44 eventually
	height: 60px;
	position: relative;
	margin: 0 auto;
	display: block;
	background: url(../images/icons/tick.png) no-repeat left top / 2900%;
	cursor: pointer;

	&.animate {
		animation: tickPop 1s steps(28) forwards;
	}
}

@keyframes tickPop {
	from { background-position: left top; }
	to { background-position: right top; }
}
