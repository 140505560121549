*{
	outline: none;
}

a{
	@include transition(color);
	color:$secondary-color;
	//font-size: rem-calc(14);
	font-weight: 700;
	@each $colorName, $colorValue in $colors{
		&.#{$colorName}{
			color:$colorValue;
			&.has-arrow{
				&::after{
					color:$colorValue;
				}
			}
		}
	}
	&:hover,&:focus{
		color: $primary-color;
	}
	&.large-arrow-link{
		font-size: rem-calc(24);
		padding-right: rem-calc(45);
		@include breakpoint(medium) {
			padding-right: rem-calc(60);
			font-size: rem-calc(30);
		}
		@include breakpoint(large){
			font-size: rem-calc(60);
			padding-right: rem-calc(100);
		}
		font-weight: 100;
		line-height: 1.4;
		position: relative;
		display: inline-block;
		&::after{
			@include transition(all);
			@include vertical-center;
			top: 55%;
			content: '\e902';
			font-family: Canisius;
			right: 0;
			opacity: .5;
			display: inline-block;
			font-size: rem-calc(25);
			@include breakpoint(medium) {
				font-size: rem-calc(35);
			}
			@include breakpoint(large) {
				font-size: rem-calc(55);
			}
		}
		&:hover{
			opacity: 1;
			color:rgba($white,1);
			&::after{
				opacity: 1;
			}
		}
	}
	&.has-arrow{
		&::after{
			font-family: Canisius;
			content: '\e901';
			margin-left: rem-calc(5);
			font-size: rem-calc(11);
		}
	}
}

span{
	&.has-arrow{
		display: inline-block;
		&::after{
			font-family: Canisius;
			content: '\e901';
			margin-left: rem-calc(5);
			font-size: rem-calc(9.5);
		}
		&--left{
			transform:rotate(180deg);
		}
	}
}

.large-arrow{
	margin-top: rem-calc(15);
	&::after{
		position: relative;
		content: '\e902';
		font-family: Canisius;
		right: 0;
		display: inline-block;
		line-height: 1.2;
		color:$white;
		font-size: rem-calc(30);
		@include breakpoint(medium){
			font-size: rem-calc(55);
		}
	}
}
