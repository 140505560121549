body.showBlockNames [data-block]:before {
	content: attr(data-block);
	position: absolute;
	left: 0;
	background-color: green;
	color: white;
	padding: 4px;
	z-index: 99999;
}

.relative{
	position: relative;
}