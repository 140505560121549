footer{
	position: relative;
	background: $secondary-color;
}

.footer{
	$p: &;
	&__overlay{
		pointer-events: none;
		position: absolute;
		width: 100%;
		justify-content: end;
		transform: translateY(-98%);
		flex-direction: column;
		display: flex;
		svg{
			.st0{
				fill:$secondary-color;
			}
		}
	}
	&__content{
		@extend .content--white;
		padding: rem-calc(70 0 40);
		@include breakpoint(medium) {
			padding: rem-calc(100 0 70);
		}
		h1{
			margin-bottom: 40px;
		}
		.large-arrow-link{
			color:rgba($white,.75);
		}
		p{
			margin-bottom: rem-calc(22);
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	@include breakpoint(small only) {
		&-menu {
			li{
				margin:0 !important;
				width: 100%;
				text-align: center;
			}
		}
	}
	&__social{
		background: darken($secondary-color, 6%);
		padding:rem-calc(50 0);
		@include breakpoint(small only) {
			text-align: center;
			ul {
				margin-bottom: rem-calc(20);
			}
		}
	}
	&__copy{
		@extend .content--white;
		background: darken($secondary-color, 10%);
		padding:rem-calc(30 0);
		p{
			margin:0;
			opacity: .5;
			color:#95A3D3;
		}
		@include breakpoint(small only) {
			text-align: center;
		}
	}
	&--primary{
		background: $primary-color;
		svg{
			.st0{
				fill:$primary-color;
			}
		}
		#{$p}__social{
			background: darken($primary-color, 15%);
		}
		#{$p}__copy{
			background: darken($primary-color, 20%);
			p{
				color:$white;
			}
		}
	}
}
