$submenuBackground: #fafbfd;
.page__content{
	position: relative;
}

.submenu{
	&__wrapper{
		height: rem-calc(55);
		position: relative;
	}
	&__container{
		background:$submenuBackground;
		&::after{
			height: 2px;
			width: 100%;
			background: #ecedee;
			content: '';
			position: absolute;
			bottom:0;
		}
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 10;
		transition:max-height 500ms ease-in-out;
		max-height:rem-calc(56);
		li{
			transition:max-height 500ms ease-in-out;
			max-height:rem-calc(55);
			overflow: hidden;
		}
		&:hover{
			max-height:rem-calc(999);
			li{
				max-height:rem-calc(999);
			}
		}
		ul,li{
			list-style: none;
			margin:0;
			padding:0;

		}
		.grid-container>ul>li{
			border-left: 1px solid #ecedee;
			&:last-child{
				border-right: 1px solid #ecedee;
			}
		}
		a{
			font-size: rem-calc(14);
			color:$secondary-color;
			opacity: .55;
			padding: rem-calc(9 20);
			display: block;
			font-weight: 500;
			&:hover{
				opacity: 1;
				background:rgba(#95A3D3,.1);
			}
			&.is-active{
				opacity: 1;
				color:$primary-color;
			}
		}
	}
	&__item{
		position: relative;
		span{
			@include transition(all);
			padding: rem-calc(16 20);
			font-weight: 500;
			font-size: rem-calc(14);
			font-family: $body-font-family;
			color:$secondary-color;
			display: block;
			position: relative;
		}
		&::after{
			@include transition(all);
			content: '';
			position: absolute;
			bottom:-1px;
			left: 0;
			width:100%;
			height: rem-calc(3);
			background:transparent;
			z-index: 5;
		}
		&.is-active{
			>span,>a{
				color:$primary-color;
			}
			&::after {
				background: $primary-color;
			}
		}
		ul{
			margin-top: rem-calc(-6px);
		}
	}
}
