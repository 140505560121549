.no-margin{
	margin:0 !important;
}
$margins: (
				'0' : 0,
				'1' : 1,
				'2' : 2,
				'3' : 3,
				'4' : 4,
				'5' : 5,
				'10' : 10,
				'20' : 20,
				'30' : 30,
				'40' : 40,
				'50' : 50,
				'xl': 100,
				'l': 75,
				'm': 50,
				's': 25,
);
$marginSides: (
				't' : 'top',
				'b' : 'bottom',
				'l' : 'left',
				'r' : 'right',
);
$marginBreakPoints: (
				's-': 'small',
				'm-': 'medium',
				'l-': 'large',
				'xl-': 'xlarge',
				'': 'global',
);
@each $side, $sideValue in $marginSides {
	@each $marginSize, $marginValue in $margins {
		@include breakpoint(small only) {
			.s-m#{$side}-#{$marginSize} {
				margin-#{$sideValue}: #{$marginValue}px;
			}
		}
		@include breakpoint(medium) {
			.m-m#{$side}-#{$marginSize} {
				margin-#{$sideValue}: #{$marginValue}px;
			}
		}
		@include breakpoint(large) {
			.l-m#{$side}-#{$marginSize} {
				margin-#{$sideValue}: #{$marginValue}px;
			}
		}
		@include breakpoint(xlarge) {
			.xl-m#{$side}-#{$marginSize} {
				margin-#{$sideValue}: #{$marginValue}px;
			}
		}

		.m#{$side}-#{$marginSize} {
			margin-#{$sideValue}: #{$marginValue}px;
		}
	}
}
