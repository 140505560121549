.search{
	@include transition(all);
	@extend .background--secondary;
	width: 100%;
	left: 0 !important;
	padding:0;
	border-radius: 0;
	top: $responsiveMenuHeight !important;
	@include breakpoint(large){
		top: $menuHeight !important;
	}
	form{
		position: relative;
	}
	input,input:focus{
		@include placeholder {
			color: $white;
		}
		box-shadow: none;
		background:$secondary-color;
		color:$white;
		font-weight: 100;
		padding:rem-calc(0 110px 0 0);
		border:none;
		height: rem-calc(75);
		font-size: rem-calc(14);
		@include breakpoint(medium) {
			height: rem-calc(100);
			font-size: rem-calc(30);
		}
	}
	&__submit{
		height: 100%;
		color:$white;
		position: absolute;
		top: 0;
		right: 0;
		font-weight: 700;
		font-size: rem-calc(14);
		margin:0;
		i{
			margin-left: rem-calc(10);
			position: relative;
			top: 1px;
		}
	}
	&__mobile{
		display: block;
		position: absolute;
		//background: #000;
		padding: 20px;
		height: 100%;
		right: 59px;
		i{
			&::before{
				font-size: rem-calc(16);
			}
		}
	}
}
