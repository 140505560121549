.footer-menu{
	margin:0;
	li{
		display: inline-block;
		a{
			color:$white;
			opacity: .5;
			font-weight: 500;
			&:hover{
				color:$white;
				opacity: 1;
			}
		}
		+li{
			margin-left:39px;
		}
	}
}