.google-maps{
	&__card{
		@include aspect-ratio(16,9);
	}
	&__content{
		position: relative;
		z-index: 1;
		@include breakpoint(medium) {
			padding-top: 150px;
		}
		@include text-spects {
			white-space: nowrap;
		}
	}
}