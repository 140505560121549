.logo-list{
	$p: &;
	&__item{
		padding:rem-calc(0 10px) !important;
		margin-bottom:rem-calc(50);
		&:nth-last-child(-n+2) {
			margin-bottom: 0;
		}
		@include breakpoint(medium) {
			&:nth-last-child(-n+4) {
				margin-bottom: 0;
			}
		}
		@include breakpoint(large) {
			&:nth-last-child(-n+6) {
				margin-bottom: 0;
			}
		}
		img{
			-webkit-filter: saturate(0) grayscale(100%) brightness(69%) contrast(1000%);
			filter: saturate(0) grayscale(100%) brightness(69%) contrast(1000%);
			opacity: .5;
		}
	}
	&__vertical{
		#{$p}__item{
			margin-bottom:rem-calc(100);
			&:nth-last-child(-n+2) {
				margin-bottom: 0;
			}
		}
	}
}