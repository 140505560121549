.breadcrumbs{
	margin-bottom: rem-calc(15);
	li{
		a{
			font-weight: 700;
			font-size: rem-calc(14);
			color: rgba($white,.4);
			&:hover{
				text-decoration: none;
				color: rgba($white,1);
			}
		}
		&::after{
			font-size: rem-calc(10);
			content:"\e90c" !important;
			font-family: Canisius;
			margin: rem-calc(0 12 0 10) !important;
		}
		&:last-child{
			a{
				pointer-events: none;
				color: rgba($white,1);
			}
			&::after{
				display: none;
			}
		}
	}
}
