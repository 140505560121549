.position-right{
	overflow: visible;
	background: transparent;
	.off-canvas__inner{
		position: relative;
		overflow: hidden;
		overflow-y: auto;
		width: calc(100% - 60px);
		left: 60px;
		height: 100%;
		touch-action: manipulation;
		-webkit-overflow-scrolling: touch;
		background:$white;
	}
	&.is-open {
		+ .js-off-canvas-overlay + .resp-menu--close{
			left: 0;
		}
	}
	.menu-button{
		width: 60px;
		height: 60px;
		background: $white;
		transform:translateY(0);
		top: 0;
		left: 0px;
		.hamburger{
			//left: 20px;
		}
	}

}

.off-canvas{
	z-index: -5;
	z-index: 9999;
	&-content{
		position: relative;
		z-index: 5;
	}
	&.is-open{
		z-index: 9999;
	}
}

.resp-menu--close{
	border-radius: 0;
	height: 60px;
	width: 60px;
	background: $white;
	position: fixed;
	left: -60px;
	top: 0;
	z-index: 5000;
	line-height: 1.9;
}

.resp-btn-menu{
	margin:0;
	padding:0;
	list-style: 0;
	li{
		background:#F0F2F5;
		height: 60px;
		font-weight: 700;
		color: $secondary-color;
		padding: rem-calc(8 0);
	}
	a,span{
		padding: rem-calc(10 15 9) !important;
		display: block;
		text-align: center;
	}
}

.resp-menu{
	@extend .menu;
	@extend .vertical;
	@extend .drilldown;
	label{
		background:#F0F2F5;
		height: 60px;
		font-weight: 700;
		color: $secondary-color;
		padding:rem-calc(18);
	}
	ul{
		@extend .menu;
		@extend .vertical;
	}
	li{
		text-align: center;
	}
	a,span{
		border-bottom:1px solid #E5E5E5;
		font-size: rem-calc(14);
		position: relative;
		color: $body-font-color;
		&:hover,
		&.child-active,
		&.is-active{
			color:$secondary-color;
		}
	}
	.is-drilldown-submenu-parent{
		> a {
			&::after {
				@include vertical-center;
				right: rem-calc(20);
				font-family: Canisius;
				content: '\e90c';
			}
		}
	}
	.js-drilldown-back{
		a {
			padding: $drilldown-padding;
			font-weight: 700;
			display: block;
			line-height: 1;
			position: relative;
			&::before {
				position: absolute;
				top: rem-calc(20);
				transform: rotate(180deg);
				left: rem-calc(20);
				font-family: Canisius;
				content: '\e90c';

			}
		}
	}
	&--small{
		&:first-of-type{
			margin-top:50px;
		}
		a{
			margin-bottom: 0;
			font-weight: 500;
			border:0;
			padding: rem-calc(13);
			//color: #B1B1B1;
		}
		ul{
			li{
				border-bottom:1px solid #E5E5E5;
				+li{
					border-bottom:0;
					a {
						padding: rem-calc(10) !important;
					}
				}
			}
		}
	}
}
.drilldown {
	border-top: 1px solid #E5E5E5;
	margin-bottom: 20px;
}
.menu-button{
	//right: 20px !important;
	width: 60px;
	height: 60px;
	background: $white;
	transform:translateY(0);
	top: 0;
	position: absolute;
	right: 0;
	border-radius: 0;
	-webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

.hamburger {
	@include absolute-center;
	//right: 0;
	width:25px;
	height:rem-calc(20);
	display: inline-block;
	cursor: pointer;
	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	font: inherit;
	color: inherit;
	text-transform: none;
	border: 0;
	margin: 0;
	overflow: visible;
	@include border-radius(3px);
	.hamburger-box {
		width: 25px;
		height: 16px;
		display: inline-block;
		position: relative;
		left:0;
		top: 0;
		.hamburger-inner {
			display: block;
			top:0;
		}
	}
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
	width: 25px;
	height: 2px;
	background-color: $body-font-color;
	position: absolute;
	transition-property: transform;
	transition-duration: 0.15s;
	transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
	content: "";
	display: block;
}
.hamburger-inner::before {
	top: rem-calc(10);
}
.hamburger-inner::after {
	bottom: rem-calc(10);
}
.hamburger--slider{
	.hamburger-inner {
		&::before {
			top: 8px;
			transition-property: transform, opacity;
			transition-timing-function: ease;
			transition-duration: 0.2s;
		}
		&::after {
			top: 16px;
		}
	}
}
.off-canvas.position-right {
	.menu-button {
		//&[aria-expanded="true"]{
		.hamburger-inner {
			transform: translate3d(0, 7px, 0) rotate(45deg);

			&::before {
				transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
				opacity: 0;
			}

			&::after {
				top: rem-calc(11);
				transform: translate3d(0, -11px, 0) rotate(-90deg);
			}
		}

		//}
	}
}
.hamburger-toggle {
	height: 59px;
	@include transition(all);
	&[aria-expanded="true"]{
		opacity: 0;
	}
}

