@mixin bannerHeight {
	height: rem-calc(400);
	@include breakpoint(medium) {
		height: rem-calc(626);
	}
}

.full-carousel{
	@include bannerHeight;
	&__slides{
		@include bannerHeight;
		overflow: hidden;
	}
	position: relative;
	&__slide{
		@include cover-bg;
		@include bannerHeight;
		position: relative;
		.grid-container{
			z-index: 5;
			position: relative;
		}
		&::before{
			@include transition(all);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 1;
			content: '';
			height: 100%;
			opacity: .75;
			background: linear-gradient(to right, rgba($primary-color, .9) 0%, rgba($white, 0) 50%);

		}
		.grid-container{
			position: relative;
			z-index: 5;
			height: 100%;
			.grid-x{
				@extend .align-middle;
				height: 100%;
			}
			@include breakpoint(1490px) {
				.cell{
					margin-left: $pullLeft;
				}
			}
		}
		@include text-spects{
			color:$white;
		}
	}
	&__title{
		color:$white;
		font-family: $cocogoose;
		font-size: rem-calc(30);
		line-height: 1.1;
		@include breakpoint(medium) {
			font-size: rem-calc(60);
		}
	}
	&__subtitle{
		color:$white;
		font-weight: 700;
		text-transform: uppercase;
		font-size: rem-calc(14);
		margin-bottom: rem-calc(11);
	}
	.large-arrow{
		display: inline-block;
		margin-top:rem-calc(5);
		&::after{
			opacity: 1;
		}
	}
	&__pagination{
		top:rem-calc(20px);
		margin:0;
		list-style-type: none;
		width: rem-calc(1100);
		margin-left: rem-calc(15);
		position: absolute;
		@include breakpoint(1490px) {
			@include horizontal-center;
			margin-left: $pullLeft;
		}
		li{
			margin:rem-calc(0 5px);
			display: inline-block;
			cursor: pointer;
			width: 13px;
			height: 13px;
			border:2px solid $white;
			@include border-radius(4px);
			&.slick-active{
				background:$white;
			}
		}
		&-container{
			position: relative;
		}
	}

  .sticker {
    position: absolute;
    width: 283px;
    height: 283px;
    right: 0;
    top: 20%;
    background-repeat: no-repeat;
    background-size: cover;

    @include breakpoint(medium down) {
      transform: scale(0.7);
      top: 0;
      right: -52px;
    }

    @include breakpoint(small down) {
      transform: scale(0.5);
      top: -50px;
    }

    &__link {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 100%;
      width: 100%;
      color:$white;
    }

    &__title {
      font-size: rem-calc(24);
      font-family: $cocogoose;
      display: block;
    }

    &__content {
      transform: rotate(342deg);
      text-align: center;
      width: 90%;
      line-height: 1;
    }

    &__subtitle {
      font-size: rem-calc(17);
    }
  }
}
